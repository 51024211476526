import React from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ProductsPriceListTab extends React.Component {
  constructor(props) {
    super(props)
    this.searchInput = React.createRef()
    this.state = {
      data: [],
      isFetching: true,
      per_page: 25,
      total_pages: 1,
      current_page: 1,
      query: ''
    }
  }

  componentDidMount() {
    this.fetchProducts()
  }

  fetchProducts = () => {
    if (this.state.query) {
      fetch(
        BACKEND_URL +
          '/price_lists/' +
          this.props.id +
          '/product_pricelist' +
          '?page=' +
          this.state.current_page +
          '&per_page=' +
          this.state.per_page +
          '&q=' +
          this.state.query,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            data: data['table'],
            isFetching: false,
            total_pages: Math.ceil(data['count'] / this.state.per_page)
          })
        )
        .catch((e) => console.log(e))
    } else {
      fetch(
        BACKEND_URL +
          '/price_lists/' +
          this.props.id +
          '/product_pricelist' +
          '?page=' +
          this.state.current_page +
          '&per_page=' +
          this.state.per_page,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            data: data['table'],
            isFetching: false,
            total_pages: Math.ceil(data['count'] / this.state.per_page)
          })
        )
        .catch((e) => console.log(e))
    }
  }

  handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  }

  handlePagination = (target) => {
    if (target.id === 'first-page') {
      if (this.state.current_page !== 1) {
        this.setState({ current_page: 1 }, this.fetchProducts)
      }
    } else if (target.id === 'last-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState({ current_page: this.state.total_pages }, this.fetchProducts)
      }
    } else if (target.id === 'previous-page') {
      if (this.state.current_page !== 1) {
        this.setState({ current_page: parseInt(this.state.current_page) - 1 }, this.fetchProducts)
      }
    } else if (target.id === 'next-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState({ current_page: parseInt(this.state.current_page) + 1 }, this.fetchProducts)
      }
    }
  }

  handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale submit form
      event.preventDefault()
      const query = event.target.value
      this.setState({ current_page: 1, query: query }, this.fetchProducts)
      event.target.value = query
    }
  }

  deleteSearch = (event) => {
    this.setState({ current_page: 1, query: '' }, this.fetchProducts)
    this.searchInput.current.value = ''
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.location = '/productos/' + id + '/detalles'
  }

  render() {
    console.log('this.state.data', this.state.data)
    return (
      <div>
        <div className="row col-md-12 mb-20">
          <div className="col-md-4">
            <h4>Productos asignados a esta lista de precio:</h4>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <input
                ref={this.searchInput}
                autoComplete="off"
                onKeyDown={this.handleSearch}
                className="search-price-list-responsive"
                placeholder="Buscar producto por codigo o nombre"
              />
              {this.state.query === '' && (
                <span className="glyphicon glyphicon-search form-control-feedback" />
              )}
              {this.state.query !== '' && (
                <span className="delete-search-icon" onClick={this.deleteSearch.bind(this)}>
                  &times;
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="index-table">
          <table className="table table-hover price-list-table">
            <thead>
              <tr>
                <th>CÓDIGO</th>
                <th>NOMBRE</th>
                <th>UNIDAD DE VENTA</th>
                <th>PRECIO</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isFetching === true && (
                <tr>
                  <td>
                    <b>Obteniendo datos...</b>
                  </td>
                </tr>
              )}
              {this.state.isFetching === false &&
                this.state.data.length > 0 &&
                this.state.data.map((model, index) => (
                  <tr className="cursor-pointer" key={index}>
                    <td id={model['id']} onClick={this.showModel.bind(this)}>
                      {model['db_ref']}
                    </td>
                    <td id={model['id']} onClick={this.showModel.bind(this)}>
                      {model['name']}
                    </td>
                    <td id={model['id']} onClick={this.showModel.bind(this)}>
                      {model.package ? model.package.description : ''}
                    </td>
                    <td id={model['id']} onClick={this.showModel.bind(this)}>
                      {model['price']}
                    </td>
                  </tr>
                ))}
              {this.state.isFetching === false && this.state.data.length === 0 && (
                <tr>
                  <td>No hubo resultados</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav className="ml--60" onClick={this.handlePaginationClick}>
            {this.state.total_pages > 1 && (
              <Pagination
                current_page={this.state.current_page}
                total_pages={this.state.total_pages}
              />
            )}
          </nav>
        </div>
      </div>
    )
  }
}
