export const BonusPromoReducer = (state, action) => {
  const bonusProducts = state
  const product = action.payload

  const getProductVariants = (product) => {
    const variantSingle = [
      {
        id: product.package.id,
        dbRef: product.package.db_ref,
        package: product.package.description.toLowerCase(),
        quantity_per_package: product.package.quantity,
        description: `${product.package.description}`
      }
    ]

    const variantMultiple = [
      {
        id: product.package.id,
        dbRef: product.package.db_ref,
        package: product.package.description.toLowerCase(),
        quantity_per_package: product.package.quantity,
        description: `${product.package.description}`
      },
      {
        id: product.package.id + 1,
        dbRef: 'unidad',
        quantity_per_package: 1,
        package: 'unidad',
        description: 'Unidad'
      }
    ]
    return {
      variantSingle,
      variantMultiple
    }
  }

  switch (action.type) {
    case 'ADD_TO_BONUS_PRODUCT':
      // eslint-disable-next-line no-case-declarations
      const variants = getProductVariants(product)
      product.package = product.has_package_per_unit
        ? variants.variantMultiple
        : variants.variantSingle
      product.base_product_ids = [product.id]
      product.bonus_product_ids = [product.id]
      product.base_quantity = 0
      product.bonus_quantity = 0
      product.base_package = null
      product.bonus_package = null
      product.basePackageQuantity = null
      return [product, ...bonusProducts]

    case 'REMOVE_BONUS_PRODUCT':
      return bonusProducts.filter((item) => item.id !== product.id)

    case 'CHANGE_BASE_QUANTITY_PRODUCT':
      // eslint-disable-next-line no-case-declarations
      const findBaseQuantityProduct = bonusProducts.find((item) => item.id === product.id)

      if (findBaseQuantityProduct) {
        console.log('CHANGE BASE QUANTITY', product)
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].base_quantity = product.baseQuantity
        return [...bonusProducts]
      }
      return state

    case 'CHANGE_BASE_PACKAGE_PRODUCT':
      // eslint-disable-next-line no-case-declarations
      const findBasePackageProduct = bonusProducts.find((item) => item.id === product.id)

      if (findBasePackageProduct) {
        console.log('CHANGE BASE PACKAGE', product)
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].base_package = product.basePackage.toLowerCase()
        bonusProducts[productIndex].base_package_quantity = product.basePackageQuantity
        bonusProducts[productIndex].basePackageQuantity = product.basePackageQuantity
        return [...bonusProducts]
      }
      return state

    case 'CHANGE_BONUS_QUANTITY_PRODUCT':
      // eslint-disable-next-line no-case-declarations
      const findBonusQuantityProduct = bonusProducts.find((item) => item.id === product.id)

      if (findBonusQuantityProduct) {
        console.log('CHANGE BONUS QUANTITY', product)
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].bonus_quantity = product.bonusQuantity
        return [...bonusProducts]
      }
      return state

    case 'CHANGE_BONUS_PACKAGE_PRODUCT':
      // eslint-disable-next-line no-case-declarations
      const findBonusPackageProduct = bonusProducts.find((item) => item.id === product.id)

      if (findBonusPackageProduct) {
        console.log('CHANGE BASE PACKAGE', product)
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].bonus_package = product.bonusPackage.toLowerCase()
        return [...bonusProducts]
      }
      return state

    case 'CLEAR_BONUS_PRODUCT':
      return []

    default:
      return state
  }
}
